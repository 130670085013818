$(function () {
    
    //dialog
    const new_reg_dialog = App.Dialog.init($("#new-reg-dialog"), "", "animationForm", "180", "75%").dialog('option', {
        position: {
            my: "center", at: "center", of: window
        }
    });
    new_reg_dialog.openDialog = function(id=null) {
        const isCopy = id !== null;
        this.dialog('option', 'buttons', [
            {
                text: "ない",
                class: "fl button dark-gray",
                click: function click() {
                    location.href = !isCopy ? "/application" : "/application/copy/" + id
                }
            },
            {
                text: "ある",
                class: "fr button blue",
                click: function click() {
                    location.href="/berth" + (isCopy ? "?id=" + id + "&type=copy" : "")
                }
            }
        ])
        .dialog({
            title: !isCopy ? CONST.label.NEW_REQUEST : "複製登録"
        })
        .dialog('open');
    };

    //bindings
    $(document).on('click', '.new-reg', show_new_dialog);
    $(document).on('click', '.copy-reg', show_copy_dialog);
    $(document).on('click', '.error_link', click_error_link);

    function show_new_dialog(e) {
        e.preventDefault();
        new_reg_dialog.openDialog();
    }

    function show_copy_dialog(e) {
        e.preventDefault();
        const id = $(this).data('id');
        new_reg_dialog.openDialog(id);
    }

    function click_error_link(e) {
        var speed = 400;
        var href= $(this).data("name");
        var target = $('[name="' + href + '"]');
        var position = target.offset().top;
        if($('#header').length > 0) {
            position = position - $('#header').height();
        }
        $('body,html').animate({scrollTop:position}, speed, 'swing');
        return false;
    }

});
