$(() => {

    const $gnavi = $('#gnavi');
    const $toggle = $('#menu-toggle');
    const $overlay = $('#overlay');
    const $container = $('#container');

    let current_scroll = $(window).scrollTop();

    $toggle.on('click', (ev) => {
        ev.preventDefault();

        //TODO:UA判定

        if ($gnavi.hasClass('open')) {
            $gnavi.removeClass('open');

            $toggle.find('i')
                .removeClass('fa-times')
                .addClass('fa-bars')
                .end()
                .find('span')
                .text('Menu');

            $overlay.addClass('is-hide');

            // メニューの背後のコンテンツの固定を解除する
            $container.css({
                position: 'relative',
                top: 'auto'
            });
            $( 'html, body' ).prop({
                scrollTop: current_scroll
            });
        } else {

            // メニューを開くときにスクロール位置はクリア
            $gnavi.find('> ul').scrollTop(0);

            $gnavi.addClass('open');

            $toggle.find('i')
                .removeClass('fa-bars')
                .addClass('fa-times')
                .end()
                .find('span')
                .text('Close');

            $overlay.removeClass('is-hide');

            // メニューの背後のコンテンツがスクロールしないように固定する
            current_scroll = $(window).scrollTop();
            $container.css({
                position: 'fixed',
                top: -1 * current_scroll
            });
        }
    });
});
