var App = App || {};

App.Ajax = {

    get: function(api, data){
        return this.ajax('GET', CONST.API_URL + api, data);
    },

    post: function(api, data){
        return this.ajax('POST', CONST.API_URL + api, data);
    },

    ajax: function(method, url, data){
        return $.ajax({
            type: method,
            url: url,
            data: data || {},
            cache: false,
            xhrFields: { withCredentials: true },
            headers: { 'X-Requested-With': 'XMLHttpRequest' }
        });
    },

    /**
     * Ajax リクエストにローダーを標示する
     */
    withLoader: function(all){

        const ajax = this.ajax;
        const obj = Object.assign({}, this);

        obj.ajax = function(method, url, params){

            // Ajax リクエストを開始
            const promise = ajax.call(this, method, url, params);

            // Loader を表示
            const loader = all ? App.Loader.all().push() : App.Loader.push();

            // Ajax と Loader の Promise を待ち合わせ
            return Promise.all([promise, loader]).then(
                ([data]) => {
                    // Loaderを非表示にする ... 連続して Ajax するときのために遅延
                    setTimeout(() => App.Loader.pop(), 0);
                    // Ajax の結果だけ返す
                    return data;
                },
                (err) => {
                    // Loaderを非表示にする ... 連続して Ajax するときのために遅延
                    setTimeout(() => App.Loader.pop(), 0);
                    throw err;
                },
            )
        };

        return obj;
    },
};
