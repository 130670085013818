var App = App || {};

App.Dialog = {
    init: function (content, title, dialogClass, h, w) {

        const DURATION = 150;
        const OFFSET = 20;

        var elem = $('<div></div>');

        var param = {
            modal: true,
            title: title || "確認",
            autoOpen: false,
            width: App.Util.getDevice() === 'sp' ? (w || $(window).width() - 20) : (w || 600),
            height: App.Util.getDevice() === 'sp' ? (h || $(window).height() - 20) : (h || 450),
            dialogClass: dialogClass || 'confirm_dialog',
            buttons: {},
            create: function (event) {
                $('.' + dialogClass + '')
                    .find('.ui-dialog-titlebar-close').remove()
                    .end()
                    .find('.ui-dialog-titlebar')
                    .append('<button class="btn-close-dialog">x</button>')
                ;
                $('.btn-close-dialog').on('click', function () {
                    $('.ui-widget-overlay').velocity({ opacity: 0 }, {duration:DURATION,display:"none"});
                    $('.' + dialogClass + '')
                        .velocity({ top: "+="+OFFSET+"px", opacity:0 }, { display:"none", duration:DURATION})
                        .delay(150).queue(function (next) {
                            $(event.target).dialog('close');
                            next();
                        })
                    ;
                })
            },
            open: function () {
                $('.' + dialogClass + '').delay(10).queue(function (next) {
                    $(this)
                        .velocity({ top: "+="+OFFSET+"px" }, { duration: 0 })
                        .velocity({
                            opacity: 1,
                            top: "-="+OFFSET+"px"
                        }, {
                            duration: DURATION
                        });
                    $(this).find(':input').blur();
                    next();
                });
                $('.ui-widget-overlay').hide().fadeIn(200);
                // fix caret position bug @ iOS11 safari
                // $('body').addClass('pos-f');
            },
            close: function () {
                $('.' + dialogClass + '').delay(10).queue(function (next) {
                    $(this).velocity({ top: "+="+OFFSET+"px", opacity:0 }, { display:"none", duration:DURATION})
                    next();
                });
                // fix caret position bug @ iOS11 safari
                // $('body').removeClass('pos-f');
            }
        };

        elem.html(content.show());
        elem.dialog(param);

        return elem;
    }
};
