$(() => {
    const $toggle = $('.toggle-accordion');

    $toggle.on('click', (ev) => {
        ev.preventDefault();
        const $target = $(ev.target);
        const $menu = $target.next();

        if ($target.hasClass('active')) {
            $target.removeClass('active');
        } else { 
            $target.addClass('active');
        }

        if ($menu.hasClass('is-hide')) {
            $menu.removeClass('is-hide').velocity('slideDown', {duration:200})
        } else {
            $menu.velocity('slideUp', { duration:200, complete: () => { 
                    $menu.addClass('is-hide');
                }
            })
        }
    });
});
