var App = App || {};

//バリデーションエラーの表示位置
App.error_placement = function (error, element) {


    if (element.attr('name') == "tel_number_0" || element.attr('name') == "tel_number_1" || element.attr('name') == "tel_number_2") {
        error.insertAfter($('[name = tel_number_2]'));
    } else if (element.attr('name') == "date_from" || element.attr('name') == "date_to") {
        error.insertAfter($('[name = date_to]'));
    } else {
        error.insertAfter(element);
    }
}
