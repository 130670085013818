var App = App || {};

App.Notify = (function(){

    function show(options) {
        //Noty.closeAll();
        return new Noty(options).show()
    }

    return {

        /**
         * すべての通知を閉じる
         */
        closeAll() {
            Noty.closeAll();
        },

        /**
         * OK メッセージボックスを出す
         */
        ok(msg, callback) {
            Noty.closeAll();
            const noty = new Noty({
                text: msg,
                modal: true,
                layout: 'center',
                buttons: [
                    Noty.button('OK', 'button blue', function(){
                        noty.close();
                    }, {'data-status': 'ok'}),
                ],
                callbacks: {
                    afterClose() {
                        if (callback) {
                            callback();
                        }
                    },
                }
            }).show();
        },

        /**
         * 確認ダイアログを表示
         *
         * @param msg
         * @param callback
         */
        confirm(msg, callback) {
            let ok = false;
            const noty = show({
                text: msg,
                type: 'alert',
                layout: 'center',
                modal: true,
                animation: {
                    open: 'animated fadeInUp ms-200',
                    close: 'animated fadeOutDown ms-200'
                },
                buttons: [
                    Noty.button('はい', 'button blue', function(){
                        noty.close();
                        ok = true;
                    }, { id: 'button1', 'data-status': 'ok' }),
                    Noty.button('いいえ', 'button gray', function(){
                        noty.close();
                    }),
                ],
                callbacks: {
                    afterClose() {
                        if (ok) {
                            callback();
                        }
                    },
                    onShow() {
                        $(':input , .button').blur();
                    }
                }
            })
        },

        /**
         * エラーメッセージを画面中央に表示
         *
         * @param msg
         */
        error(msg) {
            if ($.isArray(msg)) {
                msg = msg.join("<br>");
            }
            show({
                text: msg,
                type: 'error',
                layout: 'center',
                timeout: 3000,
                animation: {
                    open: 'animated shake',
                    close: 'animated fadeOutDown ms-200'
                }
            })
        },

        /**
         * 通知メッセージを画面右上に表示
         *
         * @param msg
         */
        info(msg) {
            if ($.isArray(msg)) {
                msg = msg.join("<br>");
            }
            show({
                text: msg,
                type: 'success',
                animation: {
                    open: 'animated fadeInRight',
                    close: 'animated fadeOutRight'
                },
                timeout: 3000,
                progressBar: true
            })
        },

        reserve(msg, callbacks) {
            if ($.isArray(msg)) {
                msg = msg.join("<br>");
            }
            return show({
                callbacks: callbacks,
                text: msg,
                type: 'provision',
                layout: 'bottom',
                container: '#provision-list',
                animation: {
                    open: 'animated fadeInUp',
                    close: 'animated fadeOutdown'
                }
            })
        }

    }

}());
