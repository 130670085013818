var App = App || {};

App.Session = (function(){

    let has_session = null;

    $(function () {
        //App.Session.check();
    });

    return {
        /**
         * ログイン済かどうかを返す
         */
        has: function () {
            return has_session;
        },

        /**
         * ログイン済になったときに実行されるコールバックを登録する
         */
        must_login(callback) {

            // ログイン完了
            $(window).on('login_ok', function () {

                callback();

                // コンテンツを表示
                $('body').removeAttr('data-cloak');
            });

            $(window).on('login_ng', function () {

                // コンテンツを非表示
                $('body').attr('data-cloak', 'data-cloak');

                // メッセージを表示
                App.Notify.error(CONST.msg.error.NEED_LOGIN);

                // 少し待ってから自動的にログイン画面を出す
                setTimeout(function(){
                    App.Notify.closeAll();
                    // App.Session.show_login_modal();
                }, 1000)
            });
        },

        /**
         * ログインチェックの完了時に結果とともに呼ばれるコールバックを登録する
         */
        optional_login(callback) {
            $(window).on('login_ok', function (ev, data) {
                callback(data.session);
            });
            $(window).on('login_ng', function () {
                callback();
            });
            // コンテンツを表示
            $('body').removeAttr('data-cloak');
        },

        check: async function () {
            // ローダーをログインイベントの後まで表示する
            const loader = App.Loader.all().push();

            try {
                let session;

                try {
                    session = await App.Ajax.get('/session');
                } catch (err) {
                    // レスポンスコードによらずこのタイミングで失敗すれば未ログインと扱う
                    session = {ok: false};
                }

                // ローダーの表示を待つ
                await loader;

                if (session.ok == "false") {
                    has_session = false;
                    $(window).trigger('login_ng');
                } else {
                    has_session = true;
                    $(window).trigger('login_ok', {session: session});
                }

            } finally {
                App.Loader.pop();
            }
        },

        login: async function (params) {

            // ローダーをログインイベントの後まで表示する
            const loader = App.Loader.all().push();

            try {
                const data = await App.Ajax.post('/login', params);
                if (data.errors) {
                    App.Notify.error(data.errors);
                    return;
                }
                await App.Ajax.post('/session', {ok:true});

                const session = await App.Ajax.get('/session');

                // if (!session.ok) {
                //     // ログインに成功したのに session が得られないなら有効期限切れで強制変更だった可能性がある
                //     if (data.pw_expired) {
                //         // メールアドレスやパスワードポリシーをセッションストレージに保存する
                //         sessionStorage.setItem('password-expire', JSON.stringify(data));
                //         App.FlashMessage.ok(`パスワードがしばらく変更されていません。<br>パスワードの変更をお願いします。`);
                //         location.href = '/password-change.html';
                //         return;
                //     }

                //     App.Notify.error("ログインに失敗しました");
                //     return;
                // }

                // ローダーの表示を待つ
                await loader;

                has_session = true;

                // 有効期限切れならメッセージを表示してパスワード変更画面に遷移
                // if (data.pw_expired) {
                //     App.FlashMessage.ok(`パスワードがしばらく変更されていません。<br>パスワードの変更をお願いします。`);
                //     location.href = '/password-change.html';
                //     return;
                // }

                $(window).trigger('login_ok', {session: session});
                // App.Notify.info(CONST.msg.success.LOGIN);

            } finally {
                App.Loader.pop();
            }
        },

        logout: async function (ev) {

            ev.preventDefault();

            try {
                const data = await App.Ajax.withLoader(true).post('/logout');
                if (data.errors) {
                    throw data.errors;
                }
                has_session = false;

                //mock:session false
                await App.Ajax.post('/session', { ok:false});

                //ログアウト時はログインページへ
                App.FlashMessage.push(CONST.msg.success.LOGOUT);
                location.href = "/login.html";

            } catch (err) {
                App.Notify.error(CONST.msg.error.LOGOUT);
                if (err instanceof Error) {
                    throw err;
                }
            }
        }
    }
}());
