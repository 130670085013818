$(() => {
    // Firefox のブラウザバックで js が発動しない対策
    $(window).on('unload', function(){});

    // 戻るボタンをブラウザバックにする
    $('.back-button').on('click', (ev) => {
        ev.preventDefault();
        history.back();
    });
});
