var App = App || {};

App.Loader = (function() {

    // 現在のローダー
    let elem = null;

    // 次の表示時に使うことが予約されたローダ
    let next = null;

    // ローダーの表示カウンター
    let num = 0;


    let initial = false;

    // ローダーの表示が完了したときに解決される Promise のキュー
    // ローダーの表示が完了済なら null で push は解決済 Promise を返す
    let queue = null;

    $(function(){
        // ページ遷移直後のローダーは全画面を覆う
        elem = $("#loader");
        // コンテンツのローダーは初期状態では非表示
        $("#loader-content").hide();
    });

    function show(complete) {
        elem.velocity({ 'opacity': 1 }, {
            duration: 400,
            display: 'block',
            complete: complete
        });
    }

    function hide() {
        elem.velocity('fadeOut', {
            duration: 400
        });
    }

    return {
        all: function(){
            // 次に表示するローダーを全画面にする
            next = $("#loader");
            return this;
        },
        push: function(){
            if (num++ === 0 && elem) {
                if (next) {
                    elem = next;
                    next = null;
                }
                if (!initial) {
                    initial = false;
                    queue = queue || [];
                    show(() => {
                        // ローダーが表示しきったらすべての Promise を解決する
                        queue.forEach(resolve => resolve());
                        queue = null;
                    });
                }
            }
            if (queue) {
                // 表示が途中なので Promise をキューに入れる
                return new Promise(resolve => queue.push(resolve));
            } else {
                // 表示が完了しているので解決済 Promise を返す
                return Promise.resolve();
            }
        },
        pop: function(){
            if (--num === 0 && elem) {
                hide(elem);
                // 全画面ローダーが消えた後はコンテンツだけのローダーを使う
                elem = $("#loader-content");
            }
            if (num < 0) {
                num = 0;
            }
            initial = false;
        },
    };

}());
