var App = App || {};

App.Pager = (function(){

    let current = 1;
    let max_page = 0;
    let node = 5; // 奇数

    let $elem;
    let $template;

    $(function(){
        $elem = $('#pagination');
        if ($elem.length) {
            init($elem);
        }
    });

    function init(){
        $template = _.template($elem.find('[data-template]').html());

        $elem.on('click', '.pagination__item--prev a', prev);
        $elem.on('click', '.pagination__item--next a', next);
        $elem.on('click', '.pagination__item:not(is-current) a', select);
    }

    function prev(ev){
        ev.preventDefault();
        if (current > 1) {
            current = current - 1;
        }
        $elem.trigger('pagination');
    }

    function next(ev){
        ev.preventDefault();
        if (current < max_page) {
            current = current + 1;
        }
        $elem.trigger('pagination');
    }

    function select(ev){
        ev.preventDefault();
        current = parseInt($(ev.target).attr('href'));
        $elem.trigger('pagination');
    }

    return {

        /**
         * ページャークリックのイベントハンドラを登録
         *
         * @param callback
         */
        on: function(callback){
            $elem.on('pagination', callback);
        },

        /**
         * 現在のページのオフセット値を取得
         *
         * @returns {number}
         */
        offset: function(){
            return (current - 1) * CONST.param.ROWS_LIMIT;
        },

        /**
         * 現在のページ番号を取得
         *
         * @returns {number}
         */
        current: function () {
            return current;
        },

        /**
         * 現在のページ番号を設定
         *
         * @param arg
         */
        set_current: function (arg) {
            current = arg;
        },

        /**
         * ページャーの再構築
         *
         * @param total
         */
        make: function (total) {

            // 総件数が表示件数以下ならページーは表示しない
            if (total <= CONST.param.ROWS_LIMIT) {
                $elem.empty();
                return;
            }

            // ページ数
            max_page = Math.ceil(total / CONST.param.ROWS_LIMIT);

            // node 数から作成する pager ボタンの範囲を計算
            let _min = parseInt(current) - Math.floor(node / 2);
            let _max = parseInt(current) + Math.floor(node / 2);

            // minチェック
            if (_min <= 0) {
                _max = _max + Math.abs(_min - 1);
                _min = 1;
            }

            // maxチェック
            if (_max > max_page) {
                _min = _min - (_max - max_page);
                if (_min <= 0) _min = 1;
                _max = max_page;
            }

            let pages = [];

            for (let i = _min; i < _max + 1; i++) {
                pages.push({
                    num: i,
                    current: i === parseInt(current)
                });
            }

            $elem.empty().html($template({
                pages: pages
            }));

            // 選択状態を更新
            $elem.find('.is-current').removeClass('is-current');
            $elem.find('.pagination__item a').each(function(){
                if ($(this).attr('href') === current.toString()) {
                    $(this).parent().addClass('is-current');
                }
            });
        },
    }
}());
