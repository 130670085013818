$(function () {
    $('.toggle-side').on('click', function (e) {
        e.preventDefault();
        const $t = $(this);
        const _str = 'is-show';
        if ($t.hasClass(_str)) {
            $('#side , .content-wrapper').removeClass(_str);
            $t.removeClass(_str);
        } else {
            $('#side , .content-wrapper').addClass(_str);
            $t.addClass(_str);
        }
    })
});
