$(() => {

    const $elem = $('.totop');

    $elem.on('click', function (ev) {
        ev.preventDefault();
        $(this).removeClass('is-show');
        $('html, body').velocity('scroll');
    });

    // scroll magic
    var controller = new ScrollMagic.Controller();

    new ScrollMagic
        .Scene({
            offset: 1
        })
        .on('enter', function () {
            $elem.addClass('is-show');
        })
        .on('leave', function () {
            $elem.removeClass('is-show');
        })
        .addTo(controller)
    ;
});
