var App = App || {};

App.FlashMessage = (function(){
    $(function(){
        const json = sessionStorage.getItem('FlashMessage');
        if (!json) {
            return;
        }
        sessionStorage.removeItem('FlashMessage');
        try {
            const data = JSON.parse(json);
            if (App.Notify[data.type]) {
                App.Notify[data.type](data.message);
            }
        } catch (err) {
            console.error(err);
        }
    });

    function pushFlashMessage(type, message) {
        sessionStorage.setItem('FlashMessage', JSON.stringify({ type, message }));
    }

    return {
        push(message) {
            pushFlashMessage('info', message);
        },
        info(message) {
            pushFlashMessage('info', message);
        },
        ok(message) {
            pushFlashMessage('ok', message);
        },
    };
}());
