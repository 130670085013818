/* eslint-disable */
var CONST = {
    msg:{
        error:{
            GET_FAILURE: "データの取得に失敗しました。",
            FORBIDDEN: "このページは表示できません。",
            REQUIRED: "入力必須項目です。",
            MAXLENGTH_4: "4文字以内で入力してください。",
            MAXLENGTH_20: "20文字以内で入力してください。",
            MAXLENGTH_50: "50文字以内で入力してください。",
            MAXLENGTH_100: "100文字以内で入力してください。",
            DATE: "日付の形式で入力してください。",
            NUMBER: "数値で入力してください。",
            DIGIT: "数値で入力してください。",
            EMAIL: "メールアドレスの形式が不正です。",
            PHONE: "電話番号の形式が不正です。",
            SEND_EMAIL: "メールの送信に失敗しました。",
            GPS_UNAVAILABLE: "位置情報サービスが利用できません",
            PASSWORD: "パスワードの形式が不正です",
            PASSWORD_DIF: "パスワードが一致しません",
            NEED_LOGIN:"ログインが必要です。",
            TANTO_MAX: "担当者の上限は10人です",
            TENANT_MAX: "テナントの上限は5です",
            CANNOT_RESERVE:"予約できません",
        },
        success:{
            LOGIN: "ログインに成功しました。",
            LOGOUT: "ログアウトしました。",
            SEND_EMAIL: "メールの送信に成功しました。",
            CHANGE_PASS: "パスワードの変更に成功しました。",
            SEND_DATA: "データを送信しました。",
            GET_DATA: "データを取得しました。",
            CANCEL: "キャンセルしました"
        },
        validator: {
            minlength: $.validator.format("{0}文字以上で入力してください。"),
            complexity: "含まれる文字種が少なすぎます。<br>英大文字/英小文字/数字/記号から3種。",
        },
        info:{
            LOGIN: "ログイン済です。",
            GET_DATA: "データ取得中"
        },
        confirm:{
            CANCEL_RESERVATION: "予約をキャンセルします。<br>よろしいですか？",
            EDIT_RESERVATION: "予約を短縮します。<br>よろしいですか？",
            SIGNUP: "新規会員に登録します。<br>よろしいですか？",
            PASSWORD_CHANGE:"パスワードを変更します。<br>よろしいですか？"
        }
    },
    label: {
        USE: "使用する",
        NOT_USE: "使用しない",
        NEW_REQUEST:"新規申請",
        RESERVE_DIALOG_TITLE: "利用時間選択",
        CONFIRM_DIALOG_TITLE: "予約確認",
    },
    noty:{
        timeout:3000
    },
    param:{
        MAX_KIDS_NUM: 3,
        ROWS_LIMIT:10
    },
    API_URL: "/api",
    TABLET_API_URL: "/tab",
    TABLET_RESERVE_TIMEOUT: 30000
};

moment.locale('ja', { weekdays: ["日", "月", "火", "水", "木", "金", "土"] });
