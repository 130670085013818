$(() => {
    $('.datepicker').datepicker({
        beforeShowDay: (date) => {
            const str = $.datepicker.formatDate('yy/mm/dd', date);
            if (date.getDay() === 0) {
                return [true, 'dp-sunday', str];
            } else if (date.getDay() === 6) {
                return [true, 'dp-saturday', str];
            } else {
                return [true, 'dp-weekday', str];
            }
        }
    });
    // キー入力不可とするため、readonlyを指定
    $('.datepicker').attr('readonly', 'readonly');
});
