var App = App || {};

App.Util = (function(){

    return {

        /**
         * GET パラメータを取得
         */
        get_query_params() {
            const vars = location.search.slice(1).split("&");
            const params = {};
            for (let i = 0; i < vars.length; i++) {
                const [name, value] = vars[i].split("=");
                if (typeof params[name] === "undefined") {
                    params[name] = decodeURIComponent(value);
                } else if (typeof params[name] === "string") {
                    params[name] = [params[name], decodeURIComponent(value)];
                } else {
                    params[name].push(decodeURIComponent(value));
                }
            }
            return params;
        },

        /**
         // GETパラメータ追加
         */
        insert_url_param(key, value) {
            key = encodeURIComponent(key);
            value = encodeURIComponent(value);
            var kvp = document.location.search.substr(1).split('&');
            var i = kvp.length;
            var x;
            while (i--) {
                x = kvp[i].split('=');

                if (x[0] === key) {
                    x[1] = value;
                    kvp[i] = x.join('=');
                    break;
                }
            }
            if (i < 0) {
                kvp[kvp.length] = [key, value].join('=');
            }
            return kvp.join('&');
        },
        encode_query_data: function encode_query_data(data) {
            var ret = [];
            for (var d in data) {
                ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
            }
            return ret.join('&');
        },
        // HH:MMを数値にして返す
        t2d(t) {
            const arr = t.split(':');
            return parseFloat(parseInt(arr[0], 10) + '.' + parseInt((arr[1] / 6) * 10, 10));
        },
        // 数値をHH:MMにして返す
        d2t(d) {
            const arr = String(d).split(".");
            if (!arr[1]) arr[1] = 0;
            return App.Util.pad_zero(arr[0]) + ':' + App.Util.pad_zero(parseFloat("0." + (String(d)).split(".")[1]) * 6 * 10);
        },
        // 数値をゼロ付き文字列で返す
        pad_zero(num) {
            if (num < 10) {
                num = '0' + num;
            }
            return num
        },
        // 24時間表記にフォーマット
        cnv24(num) {
            if (num >= 24) {
                num = num - 24;
            }
            return num
        },
        // 32時間表記にフォーマット
        cnv32(num, is_to = false) {
            if(num < 8 || (is_to && num <= 8)) {
                num = num + 24;
            }
            return num
        },
        // UAからデバイス種別を取得
        getDevice: (function(){
            let device;
            let ua = navigator.userAgent;
            if (ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0) {
                device = 'sp';
            } else if (ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0) {
                device = 'tab';
            } else {
                device = 'pc';
            }
            return () => device;
        }()),
    };
}());
